import React, { useEffect, useState } from "react";
import NoData from "../../../components/noData";
import { FaBoxOpen } from "react-icons/fa";
import Loading from "../../../components/loading";
import {
  Avatar,
  Input,
  List,
  Pagination,
  Select,
  Tag,
  notification,
} from "antd";
import { SyncOutlined } from "@ant-design/icons";
import API from "../../../config/API";
import { useSelector } from "react-redux";
import { GET } from "../../../utils/apiCalls";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import "../styles.scss";
import { getOrderStatusColor } from "./getOrdersStatusColor";
import useDidUpdateEffect from "../../../shared/hook/useDidUpdate";
import Search from "antd/es/input/Search";
const actions = [
  { title: "Delivered", value: "delivered" },
  { title: "Cancelled", value: "cancelled" },
  { title: "Pending", value: "pending" },
];
const options = [
  { value: "30days", label: "last 30 days" },
  { value: "3months", label: "past 3 months" },
  { value: "6months", label: "past 6 months" },
  { value: "2023", label: "2023" },
];

const ProfileOrders = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [orders, setOrders] = useState<any[]>([]);
  const User = useSelector((state: any) => state.User.user.data);
  const [searchParams, setSearchParams] = useSearchParams();
  const currpage = searchParams.get("page") || 1;
  const [searching, setSearching] = useState(false);
  const [search, setSearch] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [page, setPage] = useState(
    isNaN(Number(currpage)) ? 1 : Number(currpage)
  );
  const pageSize = 10;
  const [meta, setMeta] = useState<any>({});
  const navigate = useNavigate();
  const [notificationApi, contextHolder] = notification.useNotification();
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const [oderStatus, setOrderStatus] = useState("");
  const FailedOrders = useSelector((state: any) => state?.FailedOrders?.items);

  const getOrders = async (current: number = page) => {
    try {
      setIsLoading(true);
      const url =
        API.ORDER_GET +
        `?order=DESC&page=${current}&take=${pageSize}&name=${search}&status=${oderStatus}&sort=${dateFilter}`;
      const response: any = await GET(url, null);
      if (response.status) {
        setOrders(response.data);
        setMeta(response?.meta);
      } else {
        notificationApi.error({ message: response.message ?? "" });
      }
    } catch (err) {
      notificationApi.error({ message: `Failed to get your Orders.` });
    } finally {
      setIsLoading(false);
      setSearching(false);
    }
  };
  const getVariantInfo = (data: any) => {
    let variantss = "";
    if (Array.isArray(data?.combination) == true) {
      data?.combination.map((item: any) => {
        variantss += `${item.value} `;
      });
    }
    return variantss;
  };
  const changePage = async (page: number) => {
    setSearchParams((params) => {
      params.set("page", String(page));
      return params;
    });
  };
  const getOrdersforPage = async () => {
    await getOrders(isNaN(Number(currpage)) ? 1 : Number(currpage));
    setPage(isNaN(Number(currpage)) ? 1 : Number(currpage));
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    getOrders();
  }, []);
  useDidUpdateEffect(() => {
    getOrdersforPage();
  }, [currpage, oderStatus, search, dateFilter]);

  return (
    <div>
      {contextHolder}
      <div className="profile-header">
        <div className="profile-txt1">My Orders </div>
        <div style={{ flex: 1 }} />
        <div style={{ fontSize: 14 }}>All Orders ({meta?.itemCount ?? 0})</div>
      </div>
      <div style={{ padding: 5 }} />
      <div className="profile-row">
        <div>
          {actions?.map((item: any, index: number) => (
            <Tag
              style={{ cursor: "pointer" }}
              color={item?.value == oderStatus ? API.COLOR : ""}
              onClick={() => {
                if (!isLoading) {
                  if (oderStatus == item?.value) {
                    setOrderStatus("");
                    return;
                  }
                  setOrderStatus(item?.value);
                  setPage(1);
                }
              }}
            >
              {item?.title}
            </Tag>
          ))}
        </div>
        <div style={{ flex: 1 }} />

        <div style={{ margin: 10 }}>
          <Select
            defaultValue="Select Date"
            style={{ width: 130 }}
            options={options}
            onChange={(v) => setDateFilter(v)}
          />
        </div>
        <div>
          <Search
            placeholder="Search all Orders"
            allowClear
            enterButton="Search"
            size="middle"
            onSearch={(sear) => {
              setSearch(sear);
              setSearching(true);
            }}
            loading={searching}
          />
        </div>
      </div>
      <div style={{ padding: 10 }} />
      {isLoading ? (
        <Loading />
      ) : orders && orders.length ? (
        <List
          className="demo-loadmore-list"
          loading={isLoading}
          itemLayout="horizontal"
          // loadMore={loadMore}
          dataSource={orders}
          size="small"
          renderItem={(item: any) => (
            <List.Item
              onClick={() => navigate(`/profile/orders/${item?.order_id}`)}
              className="order-list-item d-block d-md-flex "
              style={{ cursor: "pointer" }}
            >
              <List.Item.Meta
                key={4}
                className="mt-2"
                description={
                  Array.isArray(item?.orderItems) == true
                    ? item?.orderItems.map((orderItem: any, index: number) => (
                        <List.Item.Meta
                          key={index}
                          className="mt-2"
                          avatar={
                            <Avatar
                              shape="square"
                              src={orderItem?.image}
                              size={80}
                            />
                          }
                          title={
                            <>
                              <div className="text-capitalize d-block">{`${
                                orderItem?.name
                              } ${getVariantInfo(orderItem?.variantDetails)} (${
                                orderItem.quantity
                              } item)`}</div>
                              <div>
                                <span>Ordered on:</span>
                                <span className="text-success">{` ${moment(
                                  orderItem.createdAt
                                ).format("DD/MM/YYYY")}`}</span>
                              </div>
                              <div className="fw-bold">{`${
                                Number(orderItem?.totalPrice)?.toFixed(2) ?? ""
                              } ${Settings.currency ?? ""}`}</div>
                            </>
                          }
                        />
                      ))
                    : null
                }
              />
              <div className="d-flex justify-content-between d-md-block">
                <span className="fw-bold pe-2">
                  Total: {Number(item?.grandTotal)?.toFixed(2)}{" "}
                  {Settings.currency}
                </span>
                <Tag bordered={false} color={getOrderStatusColor(item.status)}>
                  {item?.status}
                </Tag>
              </div>
            </List.Item>
          )}
        ></List>
      ) : (
        <NoData
          icon={<FaBoxOpen size={70} color="#e6e6e6" />}
          header="No Orders Yet!!"
          text1={
            oderStatus
              ? `You have no ${oderStatus} Orders.`
              : `You have no orders. Please start shopping at ${API.NAME} and place orders`
          }
          button={"START SHOPPING NOW"}
          onclick={() => navigate("/")}
        />
      )}
      <div className="d-flex justify-content-center mt-3">
        <Pagination
          current={page || 1}
          pageSize={pageSize || 10}
          total={meta?.itemCount || 0}
          defaultCurrent={1}
          responsive={true}
          defaultPageSize={pageSize || 10}
          disabled={false}
          hideOnSinglePage={true}
          onChange={(current: any, size: any) => {
            changePage(current);
          }}
        />
      </div>
    </div>
  );
};

export default ProfileOrders;
