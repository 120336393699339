import { Form, Tag, notification } from "antd";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../styles.scss";
import EditModal from "./components/editModal";
import EditEmail from "./components/editEmail";

import EditName from "./components/editName";
import PhoneVerifyOtp from "./components/phoneVerify";
import DeactivateModal from "./deactivateModal";
import EditProfilePhoto from "./components/editProfilePhoto";
import EditPassword from "./components/updatePassword";
import EmailVerificationModal from "./components/emailVerficationModal";
import EditNameChange from "./components/editNameChange";
import EditEmailChange from "./components/editEmailChange";
import EditMobilenumberChange from "./components/editMobilenumberChange";
import EditPasswordChange from "./components/editPasswordChange";
import API from "../../../config/API";
import { update } from "../../../redux/slices/userSlice";
import { PUT } from "../../../utils/apiCalls";

const ProfileDashboard = () => {
  const User = useSelector((state: any) => state.User.user);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [open, setOpen] = useState(false);
  const [emailopen, setEmailopen] = useState(false);
  const [numberopen, setNumberopen] = useState(false);
  const [passwordopen, setPasswordopen] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [passwordType, setPasswordType] = useState<"add" | "update">("update");
  const [notificationApi, contextHolder] = notification.useNotification();
  const [verifyEmailModalVisible, setVerifyEmailModalVisible] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const dispatch = useDispatch();
  const handleeditcancel = () => {
    setModal(false);
    setModal1(false);
    setModal2(false);
    setModal3(false);
    setPasswordModal(false);
  };

  // Deactivation modal--------------
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const openVerifyEmailModal = () => {
    setVerifyEmailModalVisible(true);
  };

  const closeVerifyEmailModal = () => {
    setVerifyEmailModalVisible(false);
  };
  const updateEmail = async (values: any) => {
    try {
      const { email } = values;
      const obj = {
        email,
      };
      setEmailLoading(true);
      const url = API.USER_EMAIL_UPDATE;
      const Response: any = await PUT(url, obj);
      if (Response.status) {
        notificationApi.success({ message: "Email Updated Successfully" });
        dispatch(update(Response?.data));
        setEmailopen(false);
      } else {
        notificationApi.error({ message: Response.message });
      }
    } catch (error) {
      notificationApi.error({ message: "Something went wrong." });
    } finally {
      setEmailLoading(false);
    }
  };
  const updateName = async (values: any) => {
    try {
      const { first_name, last_name } = values;
      const obj = {
        first_name,
        last_name,
      };
      const url = API.USER_NAME_UPDATE;
      setEmailLoading(true);
      const Response: any = await PUT(url, obj);
      if (Response.status) {
        dispatch(update(Response?.data));
        notificationApi.success({ message: "Successfully Updated your Name" });
        setOpen(false);
      } else {
        notificationApi.error({ message: Response.message ?? "" });
      }
    } catch (error) {
      notificationApi.error({ message: "Something went wrong." });
    } finally {
      setEmailLoading(false);
    }
  };

  const editPassowrd = async (values: any) => {
    const url =
      passwordType == "update"
        ? API.USER_CHANGE_PASSWORD
        : passwordType == "add"
        ? API.USER_ADDNEW_PASSWORD
        : "";
    try {
      setPasswordLoading(true);
      const response: any = await PUT(url, values);
      if (response?.status) {
        notificationApi.success({ message: `Password updated successfully.` });
        dispatch(update(response?.data));
        setPasswordopen(false);
      } else {
        notificationApi.error({ message: response.message });
      }
    } catch (error: any) {
      notificationApi.error({
        message: "Something went wrong. please try again.",
      });
    } finally {
      setPasswordLoading(false);
    }
  };
  return (
    <div>
      {contextHolder}
      <div className="profile-header">
        <div className="profile-txt1">My Account</div>
      </div>
      <Form>
        <Row className="mt-md-3">
          <div className="profile-dashboard-Box1">
            <div className="profile-dashboard-txt5">User Name</div>
            <br />
          </div>
          <Col md={8}>
            <div className="profile-dashboard-Box5">{User?.data?.username}</div>
          </Col>
          <Col md={2}></Col>
          <br />
          <Col md={2}></Col>
        </Row>
        <hr className="profile" />
        <Row>
          <div className="profile-dashboard-Box1">
            <div className="profile-dashboard-txt5">Name</div>
          </div>
          <Col md={8}>
            <div className="profile-dashboard-Box5">
              {User?.data?.first_name}
              &nbsp;
              {User?.data?.last_name}
            </div>
            <div className="profile-dashboard-input1">
              {open ? (
                <EditNameChange
                  firstname={User?.data?.first_name}
                  lastname={User?.data?.last_name}
                  updateName={updateName}
                  loading={emailLoading}
                />
              ) : null}
            </div>
          </Col>
          <Col md={2}></Col>
          <br />
          <Col md={2}>
            <div
              className="profile-edit-btn"
              onClick={() => {
                setOpen(open === false ? true : false);
              }}
            >
              {/* {User?.data?.first_name ? "Edit" : "Add"} */}
              {open ? "Cancel" : "Edit"}
            </div>
          </Col>
        </Row>
        <hr className="profile" />

        <Row>
          <div className="profile-dashboard-txt5">Email Address</div>
          <br />
          <Col md={8}>
            <div className="profile-dashboard-Box5">{User?.data?.email}</div>
            <div className="profile-dashboard-input1">
              {emailopen ? (
                <EditEmailChange
                  email={User?.data?.email}
                  updateEmail={updateEmail}
                  loading={emailLoading}
                />
              ) : null}
            </div>
          </Col>
          <Col md={2}>
            {User?.data?.email ? (
              User?.data?.mail_verify ? (
                <Tag color="green" bordered={false}>
                  Verified
                </Tag>
              ) : (
                <div>
                  <Tag
                    color="orange"
                    bordered={false}
                    onClick={openVerifyEmailModal}
                    style={{ cursor: "pointer" }}
                  >
                    Verify
                  </Tag>
                </div>
              )
            ) : null}
          </Col>

          <Col md={2}>
            <div
              className="profile-edit-btn"
              onClick={() => {
                setEmailopen(emailopen === false ? true : false);
              }}
            >
              {emailopen ? "Cancel" : "Edit"}
            </div>
          </Col>
        </Row>
        <hr className="profile" />
        <Row>
          <div className="profile-dashboard-Box1">
            <div className="profile-dashboard-txt5">Mobile Number</div>
            <br />
          </div>
          <Col md={8}>
            <div className="profile-dashboard-Box5">
              {User?.data?.phone ? (
                <>
                  {User?.data?.countrycode} {User?.data?.phone}
                </>
              ) : null}
            </div>
            <div className="profile-dashboard-input1">
              &nbsp; &nbsp; &nbsp;
              {numberopen ? (
                <EditMobilenumberChange phone={User?.data?.phone} />
              ) : null}
            </div>
          </Col>
          <Col md={2}>
            {User?.data?.phone ? (
              User.data?.phone_verify ? (
                <Tag color="green" bordered={false}>
                  Verified
                </Tag>
              ) : (
                <Tag color="red" bordered={false}>
                  Not Verified
                </Tag>
              )
            ) : null}
          </Col>

          <Col md={2}>
            <div
              className="profile-edit-btn"
              onClick={() => {
                setNumberopen(numberopen === false ? true : false);
              }}
            >
              {/* {User?.data?.phone ? "Edit" : "Add"} */}
              {numberopen ? "Cancel" : "Edit"}
            </div>
          </Col>
        </Row>
        <hr className="profile" />
        <Row>
          <div className="profile-dashboard-Box1">
            <div className="profile-dashboard-txt5">Password</div>
            <br />
          </div>
          <Col md={10}>
            <div className="profile-dashboard-Box5">
              <div>{User?.data?.password ? "********" : ""}</div>
            </div>
            <div className="profile-dashboard-input1">
              {passwordopen ? (
                <EditPasswordChange
                  type={passwordType}
                  closePassword={() => setPasswordopen(false)}
                  loading={passwordLoading}
                  editPassowrd={editPassowrd}
                />
              ) : null}
            </div>
          </Col>

          <Col md={2}>
            <div
              className="profile-edit-btn"
              onClick={() => {
                if (User?.data?.password) {
                  setPasswordType("update");
                } else {
                  setPasswordType("add");
                }
                setPasswordopen(passwordopen === false ? true : false);
              }}
            >
              {/* {User?.data?.password ? "Edit" : "Add"} */}
              {passwordopen ? "Cancel" : "Edit"}
            </div>
          </Col>
        </Row>
        <hr className="profile" />
      </Form>

      <div>
        <div className="profile-dashboard-txt6">FAQs</div>
        <br />
        <p className="profile-dashboard-txt7">
          What happens when I update my email address (or mobile number)?
        </p>
        <p className="profile-dashboard-txt8">
          Your login email id (or mobile number) changes, likewise. You'll
          receive all your account related communication on your updated email
          address (or mobile number).
        </p>
        <p className="profile-dashboard-txt7">
          When will my {API.NAME} account be updated with the new email address
          (or mobile number)?
        </p>
        <p className="profile-dashboard-txt8">
          It happens as soon as you confirm the verification code sent to your
          email (or mobile) and save the changes.
        </p>
        <p className="profile-dashboard-txt7">
          What happens to my existing {API.NAME} account when I update my email
          address (or mobile number)?
        </p>
        <p className="profile-dashboard-txt8">
          Updating your email address (or mobile number) doesn't invalidate your
          account. Your account remains fully functional. You'll continue seeing
          your Order history, saved information and personal details.
        </p>
        <p className="profile-dashboard-txt7">
          Does my Seller account get affected when I update my email address?
        </p>
        <p className="profile-dashboard-txt8">
          {API.NAME} has a 'single sign-on' policy. Any changes will reflect in
          your Seller account also.
        </p>
        <br />
      </div>

      <div
        className="profile-dashboard-txt9"
        onClick={showModal}
        style={{ cursor: "pointer" }}
      >
        Deactivate Account
      </div>
      <br />

      {modal ? (
        <EditModal
          ui={<EditEmail close={handleeditcancel} />}
          open={modal}
          close={handleeditcancel}
        />
      ) : null}
      {modal1 ? (
        <EditModal
          ui={<EditName close={handleeditcancel} />}
          open={modal1}
          close={handleeditcancel}
        />
      ) : null}
      {modal2 ? (
        <EditModal
          ui={<PhoneVerifyOtp close={handleeditcancel} />}
          open={modal2}
          close={handleeditcancel}
        />
      ) : null}
      <EditModal
        ui={<EditPassword close={handleeditcancel} type={passwordType} />}
        open={passwordModal}
        close={handleeditcancel}
      />
      {modal3 ? (
        <EditProfilePhoto open={modal3} close={handleeditcancel} />
      ) : null}
      <DeactivateModal open={isModalOpen} cancelModal={handleCancel} />
      <EmailVerificationModal
        visible={verifyEmailModalVisible}
        onClose={closeVerifyEmailModal}
      />
    </div>
  );
};

export default ProfileDashboard;
