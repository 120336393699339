import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Steps,
  notification,
} from "antd";
import Meta from "antd/es/card/Meta";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import React, { useState } from "react";
import API from "../../../config/API";
import { POST, PUT } from "../../../utils/apiCalls";
import OrderStatusData from "../../../config/orderStatus.json";
import { getOrderStatus } from "../../../shared/helpers/getOrderStatus";
import { useSelector } from "react-redux";

const { Step } = Steps;

function OrderStatusCardAuth(props: any) {
  const { Option } = Select;
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [notificationApi, contextHolder] = notification.useNotification();
  const User = useSelector((state: any) => state.User.user);
  const Auth = useSelector((state: any) => state.User);
  const [loading, setLoading] = useState(false);
  const onFinish = async (values: any) => {
    const url = API.ORDER_STATUS_UPDATE + props?.data?.id;

    try {
      setLoading(true);
      const response: any = await PUT(url, values);
      if (response.status) {
        notificationApi.success({
          message: `Order Status updated successfully.`,
        });
        form.resetFields();
        setVisible(false);
        if (typeof props?.getOrderDetails == "function") {
          props?.getOrderDetails();
        }
      } else {
        notificationApi.error({ message: response.message ?? "" });
      }
    } catch (err) {
      notificationApi.error({ message: `Failed to update order Status` });
    } finally {
      setLoading(false);
    }

    const updatedStatus = {
      status: values.status,
      createdAt: moment().toISOString(),
      remarks: values.remark,
    };
  };
  return (
    <Card bordered={false}>
      {contextHolder}
      <Modal
        title="Update Order Status"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={form.submit}
            loading={loading}
          >
            Update Status
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="basic"
          onFinish={onFinish}
          layout="vertical"
          initialValues={{
            status: props?.data?.orderStatus[0]?.status,
          }}
        >
          <Form.Item
            rules={[{ required: true, message: "Please Select OrderStatus" }]}
            name={"status"}
          >
            <Select style={{ width: 200 }}>
              {OrderStatusData?.map((item: any, index: number) => {
                return (
                  <Option value={item.value} key={index}>
                    {item.title}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name={"remark"}
            rules={[{ required: true, message: "Please Enter Remarks" }]}
          >
            <TextArea rows={4} placeholder="Enter remark" />
          </Form.Item>
          <Form.Item
            label={"Select Expected Delivery date"}
            name={"deliver_date"}
            rules={[{ required: true, message: "Please Select Delivery Date" }]}
          >
            <DatePicker
              showTime
              placeholder="Select Expected Delivery date"
              onChange={(value, dateString) => {}}
              onOk={() => {}}
            />
          </Form.Item>
        </Form>
      </Modal>

      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>Order Status: {getOrderStatus(props?.data?.status)}</span>
          {Auth.auth && User?.data?.type === "seller" && (
            <Button onClick={() => setVisible(true)}>Update Status</Button>
          )}
        </div>
        <div className="text-dark">
          <div>
            Order Date: {moment(props?.data?.createdAt).format("DD/MM/YYYY")}
          </div>
        </div>
      </div>

      <div className="mt-2">
        <h5>Order Status History</h5>
        {Array.isArray(props?.data?.orderStatus) == true ? (
          <Steps
            direction="vertical"
            current={props?.data?.orderStatus?.length}
          >
            {props?.data?.orderStatus?.map(
              (statusUpdate: any, index: number) => (
                <Step
                  key={index}
                  title={getOrderStatus(statusUpdate.status)}
                  description={
                    <>
                      <div>
                        {moment(statusUpdate.createdAt).format("DD/MM/YYYY")}
                      </div>
                      <div>{statusUpdate.remark}</div>
                    </>
                  }
                />
              )
            )}
          </Steps>
        ) : null}
      </div>
    </Card>
  );
}

export default OrderStatusCardAuth;
