import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import API from "../../config/API";

const AccebilityStatement = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="Screen-box">
      <Container>
        <br />
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          <div className="heading_bredcume">
            Home <IoIosArrowForward /> About <IoIosArrowForward />
            Accessibility Statement
          </div>
          <Row>
            <Col md={6} xs={12}>
              <div className="footer_heading">Accessibility Statement</div>
              <p>
                At {API.NAME}, we are committed to ensuring digital
                accessibility for all our customers, including those with
                disabilities. We strive to continually improve the user
                experience for everyone and adhere to the Web Content
                Accessibility Guidelines (WCAG) 2.1, Level AA standards.
              </p>
              <br />
              <h4> Our Commitment to Accessibility</h4>
              <ul>
                <li>
                  Accessibility Features: We have implemented accessibility
                  features on our website to make it easier to navigate and
                  interact with our products and services.
                </li>
                <li>
                  Accessibility Training: Our team members are trained in web
                  accessibility best practices to ensure that our website is
                  inclusive and accessible to all users.
                </li>{" "}
                <li>
                  Ongoing Monitoring and Testing: We regularly monitor and test
                  our website to identify and address any accessibility issues
                  that may arise.
                </li>
                <li>
                  Feedback and Support: We welcome feedback from our customers
                  regarding the accessibility of our website. If you encounter
                  any accessibility barriers or have suggestions for
                  improvement, please contact us at{" "}
                  <a href={`mailto://${API.CONTACT_MAIL}`}>
                    {API.CONTACT_MAIL}
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
        <br />
      </Container>
    </div>
  );
};

export default AccebilityStatement;
