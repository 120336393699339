import API from "../../config/API";
import DataTable from "./DataTable";
import { useEffect, useState } from "react";
import AdminLoading from "../components/AdminLoading";
import { Button, Form, Input, Modal, notification } from "antd";
import PageHeader from "../components/PageHeader";
import CategoryUpdateModal from "./categoryModal";
import useToggle from "../../shared/hook/useToggle";
import React from "react";
import Search from "antd/es/input/Search";
import { GET } from "../../utils/apiCalls";
import { GrPowerReset } from "react-icons/gr";
import PostionModal from "./positionModal";
type ModalState = "add" | "update";
function Categories() {
  const [Notifications, contextHolder] = notification.useNotification();
  const [toggle, toggleModal] = useToggle(false);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [type, setType] = useState<ModalState>("add");
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState<any[]>([]);
  const [meta, setMeta] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [reset, setReset] = useState(false);
  const [search, setSearch] = useState("");
  const [searching, setSearching] = useState(false);
  const pageSize = 10;
  const [positionModal, togglePosition] = useToggle(false);
  const [positionSelected, setPositionSelected] = useState<any>({});

  const getCategories = async (
    currentPage: number = 1,
    search: string = ""
  ) => {
    // setLoading(true);
    try {
      const url =
        API.CATEGORY_ALL +
        `?order=DESC&page=${currentPage}&take=${pageSize}&search=${search}`;
      const data: any = await GET(url, null);
      if (data?.status) {
        setCategory(data?.data);
        setMeta(data?.meta);
      } else {
        Notifications["error"]({
          message: data?.message ?? "",
        });
      }
    } catch (err: any) {
      Notifications["error"]({
        message: "Something went wrong",
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getCategories();
  }, []);

  const receiveSelectedItem = (item: any) => {
    setSelectedItem(item);
  };
  const changePage = async (page: number, take: number) => {
    await getCategories(page,search);
    setPage(page);
    window.scrollTo(0, 0);
  };

  const onSearch = async (value: string) => {
    if (value) {
      setSearch(value);
      setReset(true);
      setSearching(true);
      await getCategories(1, value);
      setPage(1);
      setSearching(false);
    }
  };
  return (
    <>
      <div className="">
        {contextHolder}
        <PageHeader
          title={`Categories ${loading ? "" : `(${meta?.itemCount ?? 0})`}`}
        >
          <Button
            onClick={() => {
              toggleModal(true);
              setType("add");
            }}
            type="primary"
          >
            Add Category +
          </Button>
          <Search
            placeholder="Search Category Name"
            allowClear
            enterButton="Search"
            size="middle"
            onSearch={onSearch}
            loading={searching}
          />
          {reset ? (
            <Button
              type="primary"
              icon={<GrPowerReset />}
              size={"middle"}
              onClick={() => {
                getCategories();
                setReset(false);
                setPage(1);
                setSearch("");
              }}
            />
          ) : null}
        </PageHeader>
        {loading ? (
          <AdminLoading />
        ) : (
          <DataTable
            data={category}
            getCategory={getCategories}
            openModal={() => toggleModal(true)}
            getSelectedItem={receiveSelectedItem}
            changeType={() => setType("update")}
            page={page}
            pageSize={pageSize}
            changePage={changePage}
            meta={meta}
            openPosition={() => togglePosition(true)}
            setPosition={setPositionSelected}
          />
        )}
      </div>
      <CategoryUpdateModal
        open={toggle}
        modalClose={() => toggleModal(false)}
        data={selectedItem}
        getCategory={() => getCategories()}
        type={type}
        page={page}
      />
      {positionModal ? (
        <PostionModal
          open={positionModal}
          close={() => togglePosition(false)}
          data={positionSelected}
          refresh={() => getCategories(page, search)}
        />
      ) : null}
    </>
  );
}
export default Categories;
