import { Avatar, Col, List, Row, Space } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "../styles.scss";
import {
  storeOrders,
  submitCheckout,
} from "../../../redux/slices/failedOrders";
import { useNavigate } from "react-router-dom";

function FailedPaymentsScreen() {
  const FailedOrders = useSelector((state: any) => state?.FailedOrders?.items);
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  console.log(FailedOrders);
  const removeFailedOrder = (item: any) => {
    if (!Array.isArray(FailedOrders)) {
      return;
    }
    const deleted = FailedOrders?.findIndex(
      (items: any) => items?.charges?.token == item?.charges?.token
    );
    const newArray = [...FailedOrders];
    if (deleted != -1) {
      newArray.splice(deleted, 1);
    }
    dispatch(storeOrders(newArray));
  };
  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={Array.isArray(FailedOrders) ? FailedOrders : []}
        renderItem={(item, index2) => (
          <List.Item
            actions={[
              <a
                key="list-loadmore-edit"
                onClick={() => {
                  dispatch(submitCheckout(item));
                  navigation("/checkoutsuccess/3?order_retry=true", {
                    replace: true,
                  });
                }}
              >
                Complete
              </a>,
              <a
                key="list-loadmore-more"
                onClick={() => removeFailedOrder(item)}
              >
                Remove
              </a>,
            ]}
          >
            <div className="w-100" key={index2}>
              <div>Payment Reference: {item?.ref}</div>
              <div className="fw-bold">{`Products: (${item?.cart?.length})`}</div>
              {Array.isArray(item?.cart)
                ? item?.cart?.map((item: any, index: number) => (
                    <div className="FailedOrder-CartItem" key={index}>
                      <div>
                        <img
                          src={item?.image}
                          className="FailedOrder-CartItem-img"
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <Row>
                          <Col sm={6} xs={12}>
                            <div className="FailedOrder-CartItem-txt1">
                              {item?.name}
                            </div>
                            <div className="FailedOrder-CartItem-txt55">
                              Seller : <span>{item?.storeName}</span>
                            </div>
                            <div className="FailedOrder-CartItem-txt2">
                              Unit Price :{" "}
                              <span style={{ color: "#000" }}>
                                {Settings?.currency}{" "}
                                {Number(item?.buyPrice)?.toFixed(2)} *{" "}
                                {item?.quantity}
                              </span>
                            </div>
                          </Col>
                          <Col sm={6} xs={12}>
                            <div
                              className="FailedOrder-row"
                              style={{ alignItems: "center", height: "100%" }}
                            >
                              <div style={{ flex: 1 }}></div>
                              <div className="FailedOrder-CartItem-txt3">
                                <span style={{ color: "grey", fontSize: 14 }}>
                                  {Settings?.currency}{" "}
                                </span>
                                {Number(item?.totalPrice).toFixed(2)}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </List.Item>
        )}
      />
    </>
  );
}

export default FailedPaymentsScreen;
