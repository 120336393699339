import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyDkXO9ARyLonlsOwSrbbPqQpNp6FYYjXGk",
  authDomain: "connect-ecommerce.firebaseapp.com",
  projectId: "connect-ecommerce",
  storageBucket: "connect-ecommerce.appspot.com",
  messagingSenderId: "747519192386",
  appId: "1:747519192386:web:92f9bfa87b23b5ab82acdb",
  measurementId: "G-NGW5GHRCTQ",
};
const app = initializeApp(firebaseConfig);
const Auth = getAuth(app);
const GoogleProvide = new GoogleAuthProvider();
export { Auth, GoogleProvide };
