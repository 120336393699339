import React, { useEffect } from "react";
import "./styles.scss";
import { Col, Container } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import API from "../../config/API";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="Screen-box">
      <Container>
        <br />
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          <div className="heading_bredcume">
            Home <IoIosArrowForward /> About <IoIosArrowForward /> Privacy and
            Policy
          </div>
          <div className="footer_heading">Privacy and Policy</div>
          <br />
          <strong>{API.NAME} PRIVACY POLICY</strong>
          <p>
            Thank you for using {API.NAME}! We are committed to providing you
            the best online shopping and delivery experience possible. This
            Privacy Policy explains what information we collect, how that
            information is used, under what circumstances we share information,
            and the choices you can make about that information. This Policy
            applies whether you access {API.NAME} through a browser, through a
            mobile app, or through some other method.
          </p>
          <br />
          This notice applies to users of {API.NAME}’s services anywhere in the
          world, including users of {API.NAME} app and website.
          <br />
          <br />
          <div className="Policy_Text2">INFORMATION WE COLLECT </div>
          <li>
            Information you provide to us or allow others to provide to us
          </li>
          <p>
            At various points in the {API.NAME} experience, you may provide us
            with information about yourself. For example, when you create an
            account with {API.NAME}, you provide us with personal information
            like your name, email address, and zip code. And if you place an
            order with {API.NAME}, we collect information including your
            address, phone number and the details of your order.
          </p>
          <p>
            f you log into the {API.NAME} service through a third-party, we may
            receive some information about you through them. For example, if you
            choose to log into {API.NAME} with your Facebook account, we may
            receive information about your contacts. We may also offer social
            sharing tools (such as the Facebook Like button) that let you share
            actions on {API.NAME} with other sites and vice versa. You should
            check the privacy policies of these services and your settings there
            for more information.
          </p>
          <li>Technical information about usage of {API.NAME}</li>
          <p>
            When you use the {API.NAME} services, either through a browser or
            mobile app, we automatically receive some technical information
            about the hardware and software that is being used.
          </p>
          <div className="Policy_Text2">
            COOKIES AND OTHER TRACKING TECHNOLOGIES:{" "}
          </div>
          <p>
            We and our partners use various technologies to collect information,
            including cookies and device identifiers. Cookies are small text
            files that are sent by your computer when you access our services
            through a browser. We may use session cookies (which expire when you
            close your browser), persistent cookies (which expire when you
            choose to clear them from your browser), and other identifiers to
            collect information from your browser or device that helps us
            personalize your experience and remember things like your current
            zip code or that you have already logged in to {API.NAME}.
          </p>
          <p>
            We employ some third-party services to help us understand the usage
            of {API.NAME}, and these third-parties may also deploy cookies on
            our site or collect information through our mobile applications. For
            example, we use Google Analytics to understand, in a non-personal
            way, how users interact with various portions of our site you can
            learn more about information that Google may collect here{" "}
            <a
              href="http://www.google.com/policies/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              Google Privay Privacy
            </a>
            .
          </p>
          <div className="Policy_Text2"> LOG INFORMATION:</div>
          <p>
            When you use {API.NAME}, our servers will record information about
            your usage of the service and information that is sent by your
            browser or device. Logs information can include things like the IP
            address of your device, information about the browser, operating
            system and/or app you are using, unique device identifiers, pages
            that you navigate to and links that you click, searches that you run
            on {API.NAME}, and other ways you interact with the service. If you
            are logged into the {API.NAME} service, this information is stored
            with your account information.
          </p>
          <p>
            Some of the advertisements you see on the Site are selected and
            delivered by third parties, such as ad networks, advertising
            agencies, advertisers, and audience segment providers. These third
            parties may collect information about you and your online
            activities, either on the Site or on other websites, through
            cookies, web beacons, and other technologies in an effort to
            understand your interests and deliver to you advertisements that are
            tailored to your interests. Please remember that we do not have
            access to, or control over, the information these third parties may
            collect. The information practices of these third parties are not
            covered by this privacy policy.
          </p>
          <div className="Policy_Text2">HOW WE USE YOUR INFORMATION</div>
          We may use the information we collect for various purposes, including
          to:
          <ol>
            <li>
              {" "}
              Provide the {API.NAME} service to you and to improve the quality
              of the service we’re able to offer
            </li>

            <li>
              {" "}
              Allow your Personal Shopper (which includes those that shop for
              and deliver the order on your behalf) to choose your items at a
              retailer site, deliver your items to you, and call or text you
              with any updates or problems
            </li>

            <li>
              Charge you for the purchase and service costs through one or more
              payment processing partners
            </li>

            <li>
              Offer you customized content (including advertising and
              promotions), such as prominently displaying items you purchase
              frequently.
            </li>

            <li>
              {" "}
              Understand how users interact with our service as a whole in order
              to test new features and improve {API.NAME} for everyone
            </li>

            <li>
              Provide customer service, respond to your communications and
              requests, and contact you about your use of {API.NAME} (Note: You
              can opt-out of receiving promotional communications from{" "}
              {API.NAME}
              by using the settings on the Account Info page)
            </li>
          </ol>
          <br />
          <strong>WHAT WE SHARE</strong>
          <br />
          {API.NAME} is a platform that may connects users with Personal
          Shoppers who shop for and deliver the goods that you want from
          retailers. In order to make this work, we need to share information
          about you and your order with the other parties who help enable the
          service. This includes, for example, the Personal Shopper(s) who pick
          and deliver your order.
          <br />
          We also share information under the following principles:
          <br />
          <br />
          <ol>
            <li>
              · With your consent or at your direction We will share your
              information with entities outside of {API.NAME} when we have your
              consent to do so or it is done at your direction. For example:
            </li>
            <li>
              If you enter loyalty card information from a particular retailer,
              we share that information with the retailer you chose along with
              your order so that information can update your loyalty card
              account.
            </li>
            <li>
              If you share a recipe publicly on {API.NAME}, it is viewable by
              anyone along with your first name and last initial.
            </li>
            <li>
              If you invite friends to use {API.NAME} through our referral
              program InstaFriend, we will share some information with the
              friends you invite like your name and phone number.
            </li>
            <li>
              For external processing We sometimes employ third parties outside
              of {API.NAME} to process information on our behalf in compliance
              with this Privacy Policy and using other appropriate security and
              confidentiality measures
            </li>
            <li>
              For legal purposes We share your information when we believe that
              the disclosure is reasonably necessary to (a) comply with
              applicable laws, regulations, legal process, or requests from law
              enforcement or regulatory authorities, (b) prevent, detect, or
              otherwise handle fraud, security, or technical issues, and (c)
              protect the safety, rights, or property of any person, the public,
              or {API.NAME}.
            </li>
            <li>
              On a non-personal or aggregate basis We share information with
              retailers and advertisers on both a non-personally identifying
              basis (including order and delivery details but not including
              credit card information) and an aggregate basis.
            </li>
            <li>
              For business purposes We may share your information in connection
              with, or during negotiations of, any merger, sale of company
              assets, financing or acquisition of all or a portion of our
              business by another company. We may also share your information
              between and among {API.NAME}, and its current and future parents,
              affiliates, subsidiaries, and other companies under common control
              and ownership.
            </li>
          </ol>
          <strong>APPLICABLE ONLY TO USERS IN THE MENA REGION:</strong>
          <br />
          <br />
          <p>
            You agree that we may allow carefully selected third parties,
            including without limitation to marketing and advertising companies,
            our affiliates and associates, to contact you about services that
            may be of interest to you. They may contact you by telephone, SMS as
            well as by e-mail.{" "}
          </p>
          <p>
            If you change your mind about being contacted by these companies in
            the future, please let us know by using the mentioned email address
            set out below and/or by amending your profile accordingly.
          </p>{" "}
          <p>
            We may use the information that you provide to us if we are under a
            duty to disclose or share your information in order to comply with
            (and/or where we believe we are under a duty to comply with) any
            legal obligation, or in order to enforce our website Terms of
            Service and any other agreement, or to protect the rights of
            {API.NAME}. This includes exchanging information with other
            companies and organisations for the purposes of fraud protection and
            prevention.
          </p>
          <br />
          <p>
            We may share or need to transfer to or store data we have received
            from you with affiliates and subsidiaries of {API.NAME}
            (“Affiliates”) which are located in countries or jurisdictions other
            than the UAE. The laws governing data protection in these countries
            or jurisdictions may differ from the laws in the UAE. We will comply
            with the laws applicable in the UAE and take all steps reasonably
            necessary to ensure that similar and/or comparable standards for
            data protection are maintained by any Affiliates which are
            recipients of your information.
          </p>
          <br />
          <p>
            By your voluntary submission of you personal information to us, you
            are consenting to the disclosure, transfer and use of your personal
            information outside of the UAE for the purposes described in this
            Privacy Policy.
          </p>
          <br />
          <br />
          <strong> {API.NAME} APP</strong>
          <br />
          This section applies to Delivery personnel, individuals who provide
          delivery of goods from a retailer registered on {API.NAME} to a user
          who is using the service and has requested through the app or website
          to be delivered specific goods.
          <div className="Policy_Text2">DATA COLLECTION AND USAGE</div>
          <p>
            The following data is shared from {API.NAME}’s main application and
            website to the {API.NAME} Driver app to facilitate the delivery
            service:
          </p>
          <ol>
            <li>
              Data provided by users. This includes: name, email, phone number,
              address, payment method chosen for a specific transaction.
            </li>
            <li>
              Data created during usage of the service by the Driver. This
              includes:
              <br />
              Location data: {API.NAME} collects this data when the Driver app
              is running in the foreground (app open and on-screen) or
              background (app open but not on-screen) of the Driver’s mobile
              device.
            </li>{" "}
            <li>
              Location data (Drivers and delivery recipients). We collect
              precise or approximate location data from drivers mobile devices
              if they enable us to do so. {API.NAME} collects this data from the
              time a ride or delivery is requested until it is finished, and any
              time the app is running in the foreground of their mobile device.
              We use this data to enhance the use of the {API.NAME} service, so
              our users can track the progress of their delivery in real time,
              improve the pick up and drop off process and detect possible
              fraud.
            </li>
            <li>
              Transaction information: We collect transaction information
              related to the use of our services during the drop off, including
              the type of services requested or provided, order details,
              delivery information, date and time the service was provided,
              amount charged, distance traveled, and payment method.
            </li>
            <li>
              Device data: We may collect data about the devices used by the
              Driver to access our services, including the hardware models,
              device IP address, operating systems and versions, software,
              preferred languages, unique device identifiers, advertising
              identifiers, serial numbers, device motion data, and mobile
              network data.
            </li>
          </ol>
          <br />
          {API.NAME} collects and uses data to enable reliable and convenient
          delivery, and other products and services. We also use the data we
          collect:
          <ul>
            <li>To enhance the safety and security of our Drivers and users</li>
            <li>
              Using data from drivers’ or delivery persons’ devices to help
              identify unsafe driving behavior such as speeding or harsh braking
              and acceleration, and to inform them of safer driving practices.
              We also use data from delivery persons’ devices to verify the type
              of vehicles they used to provide deliveries.
            </li>
            <li>
              Using device, location, profile, usage, and other data to prevent,
              detect, and combat fraud or unsafe activities.
            </li>
            <li>
              To provide a personalized service to each user so each user can
              track the progress of the delivery of the goods ordered and have
              an as accurate as possible estimated time of arrival
            </li>
            <li>
              To enable, track and share the progress of rides or deliveries
            </li>
            <li>
              To confirm service execution in a timely and satisfactory manner
              by tracking the driver’s route and delivery speed
            </li>
            <li>
              For customer support data when needed, to investigate and address
              user concerns
            </li>
            <li>For research and development</li>
          </ul>
          <strong>{API.NAME} PICKER APP</strong>
          <br />
          <p>
            This section applies to Delivery personnel, individuals who provide
            delivery of goods from a retailer registered on {API.NAME} to a user
            who is using the service and has requested through the app or
            website to be delivered specific goods.
          </p>
          <div className="Policy_Text2">DATA COLLECTION AND USAGE</div>
          <p>
            The following data is shared from {API.NAME}’s main application and
            website to the {API.NAME} Picker app to facilitate the delivery
            service:
          </p>
          <ol>
            <li>
              Data provided by users. This includes: name, email, phone number,
              address, payment method chosen for a specific transaction.
            </li>
            <li>
              Data created during usage of the service by the Picker. This
              includes:
              <br />
              Location data: {API.NAME} collects this data when the Picker app
              is running in the foreground (app open and on-screen) or
              background (app open but not on-screen) of the Picker’s mobile
              device.
            </li>
            <li>
              Location data (Pickers and delivery recipients). We collect
              precise or approximate location data from pickers mobile devices
              if they enable us to do so. {API.NAME} collects this data from the
              time a ride or delivery is requested until it is finished, and any
              time the app is running in the foreground of their mobile device.
              We use this data to enhance the use of the {API.NAME} service, so
              our users can track the progress of their delivery in real time,
              improve the pick up and drop off process and detect possible
              fraud.
            </li>
            <li>
              Transaction information: We collect transaction information
              related to the use of our services during the drop off, including
              the type of services requested or provided, order details,
              delivery information, date and time the service was provided,
              amount charged, distance traveled, and payment method.
            </li>
            <li>
              Device data: We may collect data about the devices used by the
              Picker to access our services, including the hardware models,
              device IP address, operating systems and versions, software,
              preferred languages, unique device identifiers, advertising
              identifiers, serial numbers, device motion data, and mobile
              network data.
            </li>
          </ol>
          <br />
          <br />
          {API.NAME} collects and uses data to enable reliable and convenient
          delivery, and other products and services. We also use the data we
          collect:
          <ul>
            <li>
              To enhance the safety and security of our Pickers and users{" "}
            </li>
            <li>
              Using device, location, profile, usage, and other data to prevent,
              detect, and combat fraud or unsafe activities.
            </li>
            <li>
              For customer support data when needed, to investigate and address
              user concerns
            </li>
            <li>For research and development</li>
          </ul>
          <div className="Policy_Text2">DATA SHARING AND DISCLOSURE</div>
          {API.NAME} may share the data we collect via the Picker app:
          <br />
          With business partners (retailers, service providers), this includes
          sharing:
          <ul>
            <li>Riders’ first name, pickup and/or dropoff locations</li>
            <li>
              Delivery recipients’ first name, delivery address, and order
              information with their delivery person and business partner.
            </li>
            <li>
              We also provide riders and delivery recipients with receipts
              containing information such as a breakdown of amounts charged,
              driver or delivery person first name, route map, and such other
              information required on invoices in the country or region where
              the picker operates.
            </li>
          </ul>
          <br />
          <div className="Policy_Text2">CHANGES TO THIS POLICY</div>
          <p>
            This policy may change from time to time and any revised Policy will
            be posted at this page, so we encourage you to review it regularly.
            If we make changes, we will notify you by revising the date at the
            top of this Policy and, in some cases, we may provide you with
            additional notice (such as a notice on our homepage or sending you a
            notification).
          </p>
          <br />
        </Col>
      </Container>
    </div>
  );
};

export default Privacy;
