import React, { useMemo, useState } from "react";
import { useContext } from "react";
import { SingleProductContext } from "../singleProductContext";
import { Col, Row } from "react-bootstrap";
import "../style.scss";
import RelatedItems from "./RelatedItems";
import { Button, Divider, Image, Tabs, TabsProps } from "antd";
import { Rate } from "antd";
import Slider from "react-slick";
import { Imagesettings } from "./imageSliderSettings";
import ReviewTab from "./tabs/reviewTab";
import ProductDescription from "./tabs/productDescription";
import useDidUpdateEffect from "../../../shared/hook/useDidUpdate";
import { useSearchParams } from "react-router-dom";

function ProductCard() {
  const data = useContext(SingleProductContext);
  const productInfo = data?.data;
  const activeVariant = data?.activeVariant;
  const [searchParams, setSearchParams] = useSearchParams();
  const [productImage, setProductImage] = useState(
    activeVariant?.status == true && activeVariant?.variant?.image
      ? activeVariant?.variant?.image
      : productInfo?.image
  );
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "About the product",
      children: <ProductDescription data={productInfo} />,
    },
    {
      key: "2",
      label: "Reviews",
      children: <ReviewTab />,
    },
  ];
  function capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  const getActiveVariant = (): string => {
    let variantInfo = "";
    if (activeVariant.status == true) {
      if (Array.isArray(activeVariant?.variant?.combination) == true) {
        activeVariant?.variant?.combination.forEach((item: any) => {
          variantInfo += ` ${capitalizeFirstLetter(item.value)}`;
        });
      }
    }
    return variantInfo;
  };
  useDidUpdateEffect(() => {
    if (activeVariant?.variant?.image) {
      setProductImage(activeVariant?.variant?.image);
    }
  }, [activeVariant?.variant]);
  useDidUpdateEffect(() => {
    if (productInfo?.image) {
      setProductImage(productInfo?.image);
    }
  }, [productInfo?.image]);
  const onChange = (key: string) => {};
  return (
    <div className="product-landing pt-2">
      <Row className="mx-0 mt-2">
        <Col md={5}>
          <div style={{ position: "sticky", top: 70 }}>
            <div className="productDetails-image1">
              <Image
                width={"100%"}
                src={productImage}
                className="productDetails-ProductDetailScreen-image1"
                style={{ objectFit: "contain" }}
                alt="image1"
              />
            </div>
            <div
              style={{
                width: "100%",
                overflow: "hidden",
                marginTop: 10,
              }}
            >
              <Image.PreviewGroup
                preview={{
                  onChange: (current, prev) => {},
                }}
              >
                <Slider {...Imagesettings} className="cstm-slider mt-md-3">
                  {[
                    { url: productInfo.image },
                    ...(Array.isArray(productInfo?.productImages)
                      ? productInfo?.productImages
                      : []),
                  ]?.map((item: any, index: number) => (
                    <div className="position-relative product_slider_image_container">
                      <img
                        src={item?.url}
                        key={index}
                        className="rounded w-100 product_screen_slider_image"
                        onClick={() => {
                          setProductImage(item?.url);
                        }}
                      />
                      <div
                        className={item?.url == productImage ? "active" : ""}
                      ></div>
                    </div>
                  ))}
                </Slider>
              </Image.PreviewGroup>
            </div>
          </div>
        </Col>
        <Col md={7}>
          <div className="product-details">
            <h4 className="product-details-brand-name">
              {productInfo?.brand?.toUpperCase() ?? ""}
            </h4>
            <h1 className="productDetails-text1 productDetails-txt-bold">
              {productInfo?.name} {getActiveVariant()}
            </h1>
            <p className="mb-0 productDetails-text12">
              {productInfo?.description}
            </p>
            <div className="productDetails-details-row mt-2">
              {productInfo?.averageRating ? (
                <span className="me-2">
                  {Number(productInfo?.averageRating).toFixed(1)}
                </span>
              ) : null}

              <Rate
                disabled
                allowHalf
                value={Number(productInfo?.averageRating)}
                className="productDetails-text3"
              />
              <h6 className="productDetails-secondary-text green productDetails-text4 productDetails-margin-h-6 ms-2 mb-0">
                {productInfo?.averageRating
                  ? productInfo?.totalReviews + "  ratings"
                  : "No Ratings"}
              </h6>
            </div>
            <p className="mb-0 mt-2 mb-3 productDetails-text12">
              Seller: {productInfo?.storeDetails?.store_name}
            </p>
            <Tabs
              defaultActiveKey={searchParams?.get("review") ?? "1"}
              items={items}
              onChange={onChange}
              className="ps-0"
            />
          </div>
          <div className="productDetails-margin-v-8 mt-md-3">
            <h6 className="productDetails-txt-bold">More Details</h6>
            <h6
              className="productDetails-secondary-text productDetails-text3"
              dangerouslySetInnerHTML={{
                __html: productInfo?.specifications,
              }}
            ></h6>
          </div>
        </Col>

        <div className="productDetails-margin-b-32" />

        <Divider />

        <RelatedItems />
      </Row>
    </div>
  );
}

export default ProductCard;
