import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";
import PageSider from "./components/pageSider";
import PageHeader from "./components/pageHeader";
import ProductItem from "../../components/Product_Item";
import API from "../../config/API";
import { GET } from "../../utils/apiCalls";
import { useEffect, useState } from "react";
import Loading from "../../components/loading";
import NoData from "../../components/noData";
import { Pagination, Space, Tag, notification } from "antd";
import { useParams, useSearchParams } from "react-router-dom";
import useDidUpdateEffect from "../../shared/hook/useDidUpdate";
import React from "react";
import { useSelector } from "react-redux";
import MultiSearchProductList from "../../components/multiSearchCard/productSlider";

const getCategoryId = (cid: any): string => {
  try {
    return window.atob(String(cid));
  } catch (err) {
    return "0";
  }
};

const ProductByCategory = (props: any) => {
  const pageSize = 10;
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Notifications, contextHolder] = notification.useNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  const [meta, setMeta] = useState<any>({});
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const Location = useSelector((state: any) => state.Location.location);
  const lattitude = Settings?.isLocation === true ? Location?.latitude : "";
  const longitude = Settings?.isLocation === true ? Location?.longitude : "";
  const params = useParams();
  const [page, setPage] = useState(
    Number(searchParams?.get("page")) > 0 ? Number(searchParams.get("page")) : 1
  );
  const categoryId = searchParams.get("id")
    ? getCategoryId(searchParams.get("id"))
    : "";
  const ogcategory = searchParams.get("ogCategory");
  const [initial, setInitial] = useState(true);
  const initialValues = [
    {
      status: searchParams.get("order") === "DESC" ? true : false,
      value:
        searchParams.get("order") === "ASC" ||
        searchParams.get("order") === "DESC"
          ? searchParams.get("order")
          : "ASC",
      title: "New",
    },
    {
      status:
        searchParams.get("price") === "DESC" &&
        searchParams.get("order") === "ASC"
          ? true
          : false,
      value: "ASC",
      title: "Price: High to Low",
    },
    {
      status:
        searchParams.get("price") === "ASC" &&
        searchParams.get("order") === "ASC"
          ? true
          : false,
      value: "ASC",
      title: "Price: Low to High",
    },
  ];
  const [selectedTags, setSelectedTags] = useState<any>(initialValues);

  const getProductsBySubCategory = async (page: number) => {
    const price =
      selectedTags[1].status === true
        ? "DESC"
        : selectedTags[2].status === true
        ? "ASC"
        : "RAND";
    const order = selectedTags[0].value;
    const url =
      Settings?.type === "multi"
        ? API.PRODUCT_SEARCH_NEW_MULTI +
          `?subCategory=${
            ogcategory ? "" : categoryId
          }&order=${order}&price=${price}&page=${page}&take=${pageSize}&lattitude=${lattitude}&longitude=${longitude}&radius=${
            Settings?.radius
          }&category=${ogcategory ?? ""}`
        : API.PRODUCT_SEARCH_NEW_SINGLE +
          `?subCategory=${categoryId}&order=${order}&page=${page}&take=${pageSize}&price=${price}`;
    if (categoryId || ogcategory) {
      try {
        setLoading(true);
        const response: any = await GET(url, null);
        if (response?.status) {
          setProducts(response?.data);
          setMeta(response?.meta);
        } else {
          setProducts([]);
          setMeta({});
        }
      } catch (err: any) {
        Notifications["error"]({
          message: "Something went wrong",
          description: err.message,
        });
        setProducts([]);
      } finally {
        setLoading(false);
        setInitial(false);
      }
    } else {
      Notifications["warning"]({
        message: "Something went wrong,Please select a Category",
      });
    }
  };

  const handleChange = (index: number) => {
    const array = [...selectedTags];
    const findex = array.findIndex((item: any) => item.status === true);
    if (findex !== -1 && findex !== index) {
      array[findex].status = false;
      array[findex].value = "ASC";
    }
    array[index].status = !array[index].status;
    array[index].value = array[index].status ? "DESC" : "ASC";
    setSelectedTags(array);
    const price =
      array[1].status === true
        ? "DESC"
        : array[2].status === true
        ? "ASC"
        : "RAND";
    setSearchParams((searchParams) => {
      searchParams.set("order", array[0].value);
      searchParams.set("price", price);
      return searchParams;
    });
  };

  const changePage = async (page: number, take: number) => {
    await getProductsBySubCategory(page);
    setPage(page);
    searchParams.set("page", String(page));
    setSearchParams(searchParams);
    window.scrollTo(0, 0);
  };

  useDidUpdateEffect(() => {
    getProductsBySubCategory(page);
    window.scrollTo(0, 0);
  }, [selectedTags]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getProductsBySubCategory(1);
    setPage(1);
  }, [categoryId, Location]);

  return (
    <div className="Screen-box">
      {contextHolder}
      <Container fluid>
        <Row>
          <Col lg={2} style={{ margin: 0, padding: 0 }}>
            <div className="productByCat-PageSider">
              <PageSider />
              <br />
            </div>
          </Col>
          <Col lg={10} style={{ margin: 0 }}>
            <PageHeader
              title={searchParams.get("type")}
              page={page}
              pageSize={pageSize}
              meta={meta}
              initial={initial}
              type={Settings?.type}
              count={products?.length}
            >
              <Space wrap>
                {selectedTags.map((tag: any, i: number) => (
                  <Tag
                    color={selectedTags[i].status ? API.COLOR : ""}
                    style={{ cursor: "pointer" }}
                    key={i}
                    onClick={() => handleChange(i)}
                  >
                    {tag.title}
                  </Tag>
                ))}
              </Space>
            </PageHeader>
            {loading ? (
              <Loading />
            ) : products?.length ? (
              Settings.type === "multi" ? (
                products.map((item: any) => {
                  return (
                    <MultiSearchProductList
                      data={item}
                      type="category"
                      cid={categoryId}
                      cname={searchParams.get("type")}
                      count={3}
                      ogcategory={ogcategory}
                    />
                  );
                })
              ) : (
                <Row className="gy-3 py-3">
                  {products?.map((item: any, i: number) => (
                    <Col  xs={6} key={i} md="4" lg="3">
                      <ProductItem item={item} />
                    </Col>
                  ))}
                </Row>
              )
            ) : (
              <NoData
                header={"No Products"}
                text1={`No Products found for "${
                  searchParams.get("type") ?? ""
                }"`}
              />
            )}
            <Pagination
              current={page}
              pageSize={pageSize}
              total={meta?.itemCount || 0}
              defaultCurrent={1}
              responsive={true}
              defaultPageSize={pageSize}
              disabled={false}
              hideOnSinglePage={true}
              onChange={(current: any, size: any) => changePage(current, size)}
            />
            <br />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProductByCategory;
