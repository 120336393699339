import "./styles.scss";
import DeskTop from "./desktopHeader";
import Mobile from "./mobileHeader";
import CateogreyList from "./cateogreyList";
import React, { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import {
  useGetSettings,
  useLoadCartItems,
  useLoadCategories,
  useRefreshUser,
} from "./fetchingDatas";
import { useDispatch, useSelector } from "react-redux";
import { clearCart } from "../../redux/slices/cartSlice";
import { clearStore } from "../../redux/slices/storeSlice";
import { logout } from "../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import { logoutChannel } from "../../logout";
function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [Notifications, contextHolder] = notification.useNotification();

  const signoutUser = () => {
    Notifications["warning"]({
      message: `Your Session Expired`,
      description: "Please sign in again to continue",
    });
    logoutChannel.postMessage("Logout");
    dispatch(clearCart(null));
    dispatch(clearStore(null));
    dispatch(logout(null));
    navigate("/");
    return;
  };
  useLoadCategories();
  useLoadCartItems();
  useGetSettings();
  useRefreshUser();
  const Token = useSelector((state: any) => state?.User?.user?.token);
  if (Token) {
    const decoded: any = jwtDecode(Token);
    let currentDate = new Date();
    if (decoded.exp && decoded.exp * 1000 < currentDate.getTime()) {
      signoutUser();
    }
  }
  return (
    <div className="Header">
      {contextHolder}
      <div className="Header-desktop">
        <DeskTop />
        <CateogreyList />
      </div>
      <div className="Header-mobile">
        <Mobile />
      </div>
    </div>
  );
}
export default Header;
