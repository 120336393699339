import { Button, Card, Form, Modal, Steps, notification } from "antd";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import React, { useState } from "react";
import API from "../../../../config/API";
import { PUT } from "../../../../utils/apiCalls";

const { Step } = Steps;

function OrderStatusCard(props: any) {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [Notifications, contextHolder] = notification.useNotification();

  const cancelOrder = async (values: any) => {
    const url = API.ORDER_CANCEL + props?.data?.id;
    try {
      setLoading(true);
      const response: any = await PUT(url, values);
      if (response.status) {
        Notifications["success"]({
          message: response?.message ?? "",
        });
        setOpen(false);
        form.resetFields();
        props?.getOrderDetails();
      } else {
        Notifications["error"]({
          message: response?.message ?? "",
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: "Something went wrong.",
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {contextHolder}
      <Card bordered={false}>
        <div>
          <div className="d-flex justify-content-between">
            <h5>Order Status</h5>{" "}
            {props?.data.status == "pending" ? (
              <Button danger onClick={() => setOpen(true)}>
                Cancel Order
              </Button>
            ) : null}
          </div>
          {Array.isArray(props?.data?.orderStatus) == true ? (
            <Steps
              direction="vertical"
              current={props?.data?.orderStatus?.length}
            >
              {props?.data?.orderStatus.map(
                (statusUpdate: any, index: number) => (
                  <Step
                    key={index}
                    title={statusUpdate.status}
                    description={
                      <>
                        <div>
                          {moment(statusUpdate.createdAt).format("DD/MM/YYYY")}
                        </div>
                        <div>{statusUpdate.remark}</div>
                      </>
                    }
                  />
                )
              )}
            </Steps>
          ) : null}
        </div>
      </Card>
      <Modal
        title="Cancel Your Order"
        open={open}
        onOk={() => form.submit()}
        confirmLoading={loading}
        onCancel={() => {
          setOpen(false);
          form.resetFields();
        }}
        centered
        okText="Confirm"
      >
        <Form layout="vertical" form={form} onFinish={cancelOrder}>
          <Form.Item
            label="Reason for Cancellation"
            name={"remark"}
            rules={[
              {
                required: true,
                message: "Please enter reason for order cancellation",
              },
            ]}
          >
            <TextArea rows={3} />
          </Form.Item>
        </Form>
        <p style={{ fontSize: "12px", marginBottom: 0 }}>
          Once you cancel your order, The order will not be processed by the
          seller and The amount will be refunded to your Bank account within 2
          days if any amount is debited.
        </p>
      </Modal>
    </>
  );
}

export default OrderStatusCard;
