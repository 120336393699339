import React, { useEffect } from "react";
import { Col, Container } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import API from "../../config/API";

const Cancellation = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="Screen-box">
      <Container>
        <br />
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          <div className="heading_bredcume">
            Home <IoIosArrowForward /> About <IoIosArrowForward />
            Cancellation & Refund policy
          </div>
          <div className="footer_heading">Cancellation & Refund policy</div>
          <br />
          <h5>
            <strong>*&nbsp;</strong>When & how can I cancel my order?
          </h5>
          <ul>
            <li>
              You can cancel your order within the first 5 minutes after the
              order being placed
            </li>
            <li>Within 3 minutes after substitution request</li>
            <li>
              In any other cases, please contact {API.NAME} at{" "}
              <a href={`mailto://${API.CONTACT_MAIL}`}>
                {" "}
                &nbsp;{API.CONTACT_MAIL}
              </a>
            </li>
          </ul>
          <h5>
            <strong>*&nbsp;</strong>What if the delivered product is incorrect
            or damaged?
          </h5>
          <p>
            We highly advise you to review the items upon receiving them at the
            door. However, if for any reason you were unable to review the
            products at the time of delivery and a certain product is incorrect
            or damaged, please contact us for a replacement or refund.{" "}
            {API.NAME}
            will initiate the required action immediately; given that, the issue
            is reported within 24 hours.
          </p>
          <h6>
            Refunds will be done only through the Original Mode of Payment.
          </h6>
        </Col>
        <br />
      </Container>
    </div>
  );
};

export default Cancellation;
