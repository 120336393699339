import React, { useEffect, useMemo, useState } from "react";
import { useRef } from "react";
import { Popover } from "react-tiny-popover";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Avatar, Form, Input, Spin, Tag } from "antd";
import API from "../../config/API";
import { GET } from "../../utils/apiCalls";
import useDebounce from "../../shared/hook/useDebounce";
import { LoadingOutlined } from "@ant-design/icons";
import { LuSearch } from "react-icons/lu";
import { AiOutlineClose } from "react-icons/ai";
import useSize from "../../shared/hook/useSize";
import { useSelector } from "react-redux";
import { IoSearchOutline } from "react-icons/io5";
import useDidUpdateEffect from "../../shared/hook/useDidUpdate";
const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;
function SearchBar() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const inputElement: any = useRef();
  const searchInput: any = useRef(null);

  const [loading, setLoading] = useState<boolean>(true);

  const [voiceSearch, setVoiceSearch] = useState<boolean>(false);

  // const windowSize = useSize();
  const [popoverWidth, setPopoverWidth] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState<string>(searchParams?.get("qs") || "");
  const debounce = useDebounce(value, 200);

  const Category = useSelector((state: any) => state.Category.categries);
  const [subCategories, setSubcategories] = useState<string[]>([]);
  const [allCategories, setAllCategories] = useState<string[]>([]);
  const [recommendations, setRecommendations] = useState<string[]>([]);
  const [abortController, setAbortController] = useState<any>(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    getRecomendations();
  }, [debounce]);

  useEffect(() => {
    setPopoverWidthDynamic();
  }, [windowSize.width]);

  useEffect(() => {
    setPopoverWidthDynamic();
  }, []);

  const focusInput = () => {
    setTimeout(() => {
      inputElement?.current?.focus();
    }, 10);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const formSubmitHandler = (values: any) => {
    setOpen(false);
    navigate(`search?qs=${values?.search}`);
  };
  useDidUpdateEffect(() => {
    if (searchParams?.get("qs") == null) {
      setValue("");
    } else {
      setValue(searchParams?.get("qs") || "");
    }
  }, [searchParams?.get("qs")]);

  const setPopoverWidthDynamic = () => {
    setTimeout(() => {
      if (
        searchInput?.current?.offsetWidth &&
        searchInput?.current?.offsetWidth > 200
      ) {
        setPopoverWidth(searchInput?.current?.offsetWidth + 27);
      } else {
        if (windowSize.width < 762) {
          setPopoverWidth(windowSize.width - 10);
        } else {
          setPopoverWidth(windowSize.width / 2);
        }
      }
    }, 100);
  };
  const getAllSubcategories = async () => {
    const subcategories: string[] = [];
    const category_subcategory: string[] = [];
    Category?.forEach((item: any) => {
      category_subcategory.push(item.name);
      item?.sub_categories?.forEach((item: any) => {
        subcategories.push(item);
        category_subcategory.push(item.name);
      });
    });
    setAllCategories(category_subcategory);
    setSubcategories(subcategories);
  };

  const getRecomendations = async () => {
    setLoading(true);
    const url = API.PRODUCT_SEARCH_AUTOCOMPLETE + value;
    const controller = new AbortController();
    setAbortController(controller);
    const signal = controller.signal;
    try {
      if (abortController) {
        abortController.abort();
      }
      if (value.length) {
        const response: any = await GET(url, null, signal);
        if (response?.status) {
          setRecommendations(response?.data);
        }
      } else {
        setRecommendations([]);
      }
    } catch (err: any) {
      setRecommendations([]);
    } finally {
      setLoading(false);
    }
  };

  const filterData = (e: any) => {
    {
      setValue(e?.target?.value);
      const filtered = allCategories.filter(function (str: string) {
        return str.toLowerCase().includes((e?.target?.value).toLowerCase());
      });
      setRecommendations(filtered);
    }
  };

  const handleTagChange = (item: any) => {
    setOpen(false);
    let url = `/products/c/${item?.slug}?id=${window.btoa(
      item._id
    )}&type=${encodeURIComponent(item?.name)}`;
    navigate(url);
  };
  useMemo(() => {
    getAllSubcategories();
  }, [Category]);

  const onVoiceSearch = () => {};

  return (
    <>
      <Popover
        // ref={searchInput}
        isOpen={open}
        onClickOutside={() => setOpen(false)}
        positions={["bottom"]}
        align={"center"}
        containerStyle={{ zIndex: "1002" }}
        content={
          <div
            className="searchPlaceInputHead-popover"
            style={{ minWidth: `${popoverWidth ?? 0}px` }}
          >
            <div
              className="SearchBar-mobileDiv"
              style={{ width: popoverWidth - 30 }}
            >
              <div></div>
              <div onClick={() => setOpen(false)}>
                Close <AiOutlineClose size={26} />
              </div>
            </div>
            <div>
              <Form
                onFinish={formSubmitHandler}
                initialValues={{ search: value }}
              >
                <Form.Item name={"search"}>
                  <Input
                    ref={inputElement}
                    className="SearchBar-input"
                    onChange={(e: any) => filterData(e)}
                    placeholder="Search gifts and flowers . . .  "
                  ></Input>
                </Form.Item>
              </Form>
            </div>
            {loading ? <Spin indicator={antIcon} /> : null}
            <div
              className="SearchBar-tags"
              style={{ maxWidth: `${popoverWidth}px` }}
            >
              {value.length == 0 && loading == false ? (
                <>
                  <div className="SearchBar-txt2">Popupar Categoires</div>
                  {subCategories.slice(0, 10).map((tag: any, i: number) => (
                    <Tag
                      key={i}
                      onClick={() => handleTagChange(tag)}
                      style={{ cursor: "pointer" }}
                    >
                      {tag.name}
                    </Tag>
                  ))}
                </>
              ) : null}
            </div>
            {recommendations?.length ? (
              <>
                <div className="SearchBar-txt2">Suggestions</div>
                {recommendations.map((item: any, index: number) => {
                  if (item?.name) {
                    return (
                      <div
                        key={index}
                        className="SearchBar-item"
                        onClick={() => {
                          setValue(item?.name);
                          navigate(
                            `search?qs=${encodeURIComponent(item?.name)}`
                          );
                          setOpen(false);
                        }}
                      >
                        <div>
                          {index < 2 ? (
                            <Avatar
                              src={item?.image}
                              size={30}
                              shape="square"
                            />
                          ) : (
                            <LuSearch size={15} color="grey" />
                          )}

                          {/* <LuSearch size={15} color="grey" /> */}
                        </div>
                        <div className="SearchBar-txt1">{item?.name}</div>
                      </div>
                    );
                  }
                })}
              </>
            ) : (
              <div className="SearchBar-box">
                <LuSearch size={60} color={API.COLOR} />
                <div className="SearchBar-txt2">Recent Search</div>
                <div className="SearchBar-txt3">
                  Your recent searches appear here
                </div>
              </div>
            )}
          </div>
        }
      >
        <div className="Header-searchBox">
          <div className="Header-desk-Search" ref={searchInput}>
            <div onClick={() => setVoiceSearch(true)}>
              <IoSearchOutline size={20} color="#000" />
            </div>
            <Input
              bordered={false}
              size="small"
              onChange={() => setOpen(true)}
              placeholder="Search gifts and flowers . . .  "
              value={value}
              onClick={(e) => {
                setOpen(true);
                focusInput();
              }}
            />
          </div>
          <div className="Header-mobile-Search" onClick={() => setOpen(true)}>
            <LuSearch size={25} />
          </div>
        </div>
      </Popover>
    </>
  );
}

export default SearchBar;
